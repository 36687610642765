import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";

import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import formatDate from "../../utils/formatDate";
import { useFeaturedImage } from "../../hooks/useFeaturedImage";
import responsiveStringLength from "../../utils/responsiveStringLength";

const EventsCard = ({ article }) => {
  let _isMounted = false;

  const { childImageSharp } = useFeaturedImage();

  // Exctract article data
  let slug = article.slug;
  let originalTitle =
    article.type === "news" ? article.title : article.eventTitle;
  let date = formatDate(
    article.type === "news" ? article.createdAt : article.eventDate
  );
  let body = documentToPlainTextString(article.body.json);
  let imageExists = article.featuredImage ? article.featuredImage.fluid : false;
  let image = imageExists || childImageSharp.fluid;
  const [title, setTitle] = useState(originalTitle);
  const [excerpt, setExcerpt] = useState();
  const [detailsStyle, setDetailsStyle] = useState({
    height: "60%",
  });

  useEffect(() => {
    _isMounted = true;

    setTitle(responsiveStringLength(550, 1366, title, 180, 70, 50));
    setExcerpt(responsiveStringLength(550, 1366, body, 110, 200, 80));

    const handleResize = window.addEventListener("resize", () => {
      if (_isMounted) {
        setTitle(responsiveStringLength(550, 1366, title, 180, 70, 50));
        setExcerpt(responsiveStringLength(550, 1366, body, 110, 220, 80));
      }
    });

    return () => {
      window.removeEventListener("resize", handleResize);
      _isMounted = false;
    };
  }, []);

  return (
    <Link to={`/articles/${slug}`} className="news-card" title={originalTitle}>
      <BackgroundImage
        tag="div"
        className="image"
        fluid={image}
        backgroundColor={`#fbb040`}
      ></BackgroundImage>
      <div
        className="details"
        style={detailsStyle}
        onMouseEnter={() => {
          setDetailsStyle({
            height: "100%",
            backgroundColor: "#f9f9f9",
          });
          setExcerpt(responsiveStringLength(550, 1366, body, 330, 320, 230));
        }}
        onMouseLeave={() => {
          setDetailsStyle({
            height: "60%",
          });
          setExcerpt(responsiveStringLength(550, 1366, body, 110, 220, 80));
        }}
      >
        <p className="type">Events</p>
        <p className="date" style={{ fontWeight: 500 }}>
          {date}
        </p>
        <h3 className="title" style={{ color: "#fbb040" }}>
          {title}
        </h3>
        <p className="excerpt">{excerpt}</p>
        <div className="read-more">+ Διαβάστε περισσότερα</div>
      </div>
    </Link>
  );
};

export default EventsCard;
