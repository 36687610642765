import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import pagination from "../utils/pagination";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaLongArrowAltLeft } from "react-icons/fa";

const Pager = ({ pageContext }) => {
  let _isMounted = false;

  const { first, last, pathPrefix, index, pageCount } = pageContext;

  let previousPagePath = first
    ? false
    : index === 2
    ? pathPrefix
    : `${pathPrefix}/${index - 1}`;
  let nextPagePath = last ? false : `${pathPrefix}/${index + 1}`;

  let pages = pagination(pageCount, index, pathPrefix);

  const [styles, setStyles] = useState();
  const [responsivePages, setResponsivePages] = useState();

  useEffect(() => {
    _isMounted = true;
    let styles;
    let displayPages;
    let element = document.querySelector(".cards");
    let offsetTop = element.getBoundingClientRect().top;
    let elementBottom = element.scrollHeight + offsetTop + 90;
    let scrollPosition = window.pageYOffset + window.innerHeight;
    let innerWidth = window.innerWidth;

    // Hide pagination if 1 or less pages
    if (pageCount <= 1) {
      styles = { display: "none" };
      return setStyles(styles);
    }

    // Handle how many pages to display
    const handlePages = () => {
      innerWidth = window.innerWidth;

      // Responsive pages display
      if (innerWidth <= 500) {
        // Current page only
        displayPages = pages.filter(page => parseInt(page.key) === index);
      } else if (innerWidth <= 1050) {
        // 3 pages only
        displayPages = pages.filter(
          page =>
            parseInt(page.key) === index ||
            parseInt(page.key) - 1 === index ||
            parseInt(page.key) + 1 === index
        );
      } else {
        displayPages = pages;
      }
    };

    // Set initial style
    scrollPosition > elementBottom && setStyles({ position: "static" });
    // Set the initial number of pages to display
    handlePages();
    setResponsivePages(displayPages);

    // Resize listener
    const handleResponsivePages = window.addEventListener("resize", e => {
      handlePages();
      _isMounted && setResponsivePages(displayPages);
    });

    // Scroll listener
    const handleScroll = window.addEventListener("scroll", () => {
      let elementBottom = element.scrollHeight + offsetTop + 90;
      let scrollPosition = window.pageYOffset + window.innerHeight;

      scrollPosition > elementBottom
        ? (styles = { position: "static" })
        : (styles = { opacity: 1, visibility: "visible" });

      _isMounted && setStyles(styles);
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResponsivePages);
      _isMounted = false;
    };
  }, []);

  return (
    <div className="pagination" style={styles}>
      <div className="wrapper">
        <div className="pager">
          <Link
            to={previousPagePath ? previousPagePath : ""}
            className={`prev transparent-button ${!previousPagePath &&
              "disabled"}`}
          >
            <FaLongArrowAltLeft /> <span>Προηγούμενο</span>
          </Link>
          <ul className="pages">{responsivePages}</ul>
          <Link
            to={nextPagePath ? nextPagePath : ""}
            className={`next transparent-button ${!nextPagePath && "disabled"}`}
          >
            <span>Επόμενο</span> <FaLongArrowAltRight />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Pager;
