import React from "react";
import Transition from "../components/Transition";
import { navigate } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Breadcrumb from "../components/Breadcrumb";
import NewsCard from "../components/Articles/NewsCard";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import Pager from "../components/Pager";
import CallToAction from "../components/application-cta";
import EventsCard from "../components/Articles/EventsCard";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
  select: {
    height: 40,
    borderRadius: 0,
    border: "none",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const NewsAndEvents = ({ pageContext, location }) => {
  // Cards
  const { group, pathPrefix } = pageContext;

  const articles = group;

  let cards = articles.map(article =>
    article.node.type === "news" ? (
      <NewsCard key={article.node.id} article={article.node} />
    ) : (
      <EventsCard key={article.node.id} article={article.node} />
    )
  );

  // Filter
  const classes = useStyles();
  const [category, setCategory] = React.useState(pathPrefix);

  const handleChange = event => {
    setCategory(event.target.value);
  };

  if (category === "/news-and-events" && pathPrefix !== "/news-and-events")
    navigate("/news-and-events");
  else if (category === "/news" && pathPrefix !== "/news") navigate("/news");
  else if (category === "/events" && pathPrefix !== "/events")
    navigate("/events");

  return (
    <Layout pageTitle="news-and-events">
      <SEO pageTitle="Νέα &amp; Εκδηλώσεις" />
      <Breadcrumb location="Νέα &amp; Εκδηλώσεις" link={location.pathname} />
      <div className="cards">
        <Transition>
          <div className="title-filter">
            <h2>ΝEA &amp; ΕΚΔΗΛΩΣΕΙΣ</h2>
            <div className="filter">
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  className={classes.select}
                  id="category-select"
                  value={category}
                  onChange={handleChange}
                >
                  <MenuItem value={"/news-and-events"}>Όλα</MenuItem>
                  <MenuItem value={"/news"}>Νέα</MenuItem>
                  <MenuItem value={"/events"}>Εκδηλώσεις</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <hr />
          <div className="cards-wrapper">{cards}</div>
        </Transition>
      </div>

      <Pager pageContext={pageContext} />
      <CallToAction />
    </Layout>
  );
};

export default NewsAndEvents;
