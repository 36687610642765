import React from "react"
import PageTransition from "gatsby-plugin-page-transitions"

const Transition = ({ children }) => {
  return (
    <PageTransition
      defaultStyle={{
        transition: `all 500ms cubic-bezier(0.47, 0, 0.75, 0.72)`,
        opacity: 0,
      }}
      transitionStyles={{
        entering: {
          opacity: 0,
        },
        entered: {
          opacity: 1,
        },
        exiting: {
          opacity: 0,
        },
      }}
    >
      {children}
    </PageTransition>
  )
}

export default Transition
